/**
 * Encapsulates AppSync requests made on behalf of the creator lab. Hooks and
 * contexts are provided in hooks.js and contexts.js, respectively, for better
 * integration with React.
 *
 * @namespace api
 * @module api
 */
import {
  getUser,
  getUserPrivateRecord,
  queryMyClasses,
  listContextTypePublishes,
  listIdentityContextPublishes,
  listIdentityTypePublishes,
  createLab as createLabMutation,
  updateUser,
  updatePrivateUser,
  updatePublishMetadata
} from 'graphql/arcade';
import { updateSeat } from 'graphql/studio';
import { asyncSend } from 'graphql/handler';
import { searchAlgolia, getAlgoliaObject } from 'helpers/algolia';
import { getApiEndpoint } from 'helpers';
import { Activity, Course, Lab } from 'models';

/**
 * Creates a lab for the current user based on a provided game ID.
 *
 * @method
 * @async
 * @param {Object} options - The parameters to pass to the AppSync call
 * @param {string} options.sessionId - The ID of the session to base the lab on
 * @param {string} options.region - The region in which to launch the new lab
 * @param {string} options.envType - The environment to use for the new lab
 * @param {string} options.facilitatorCode - The facilitator code of the user's cohort
 * @returns {Promise<Object>} - An object containing the URI of the newly
 *                              created lab and any error codes that were
 *                              included
 * @throws Error Will throw an error if a lab was not able to be launched on the
 *               server side.
 */
export const createLab = async ({ sessionId, region, envType, facilitatorCode }) => {
  window.configure_amplify('arcade_endpoint');

  const payload = {
    input: {
      session_id: sessionId,
      region,
      environment_type: envType,
      arcade_appsync_id: window.ume_config.arcade_api,
      studio_appsync_id: window.ume_config.studio_api,
      facilitator_code: facilitatorCode
    }
  };

  const response = await asyncSend(createLabMutation, payload);

  if (!response.id) {
    throw new Error('Error in joining lab.');
  }

  return {
    uri: `/studio/class-${response.id}`,
    error: response.error
  };
};

/*
 * Updates the User-Beta record to indicate the session has been terminated
 */
export const endMyStudioSession = async sortKey => {
  window.configure_amplify('studio_endpoint');

  const payload = {
    input: {
      sort_key: sortKey,
      terminated_at: Date.now()
    }
  };

  const response = await asyncSend(updateSeat, payload);

  return response;
};

/**
 * Fetches the current user's activities from the publish table.
 *
 * @method
 * @async
 * @param {Object} options - Configuration for the AppSync query
 * @param {number} options.limit - The maximum number of activities to return.
 *                                 Defaults to 100.
 * @returns {Promise<Array>} - An array of activities belonging to the user
 */
export const fetchMyActivities = async (options = {}) => {
  window.configure_amplify('arcade_endpoint');

  const response = await asyncSend(listIdentityTypePublishes, {
    type: 'activity',
    limit: 100,
    filter: JSON.stringify({
      status: { ne: 'ready' },
      webview: { attributeExists: true }
    }),
    ...options
  });

  const publishes = response.items.map(data => Activity.fromPublish(data));
  publishes.sort((a, b) => b.timestamp - a.timestamp);

  return publishes;
};

/**
 * Fetches labs owned by the current user.
 *
 * @method
 * @async
 * @returns {Promise<Array>} A list of lab-format classes
 */
export const fetchMyLabs = async () => {
  window.configure_amplify('arcade_endpoint');

  const response = await asyncSend(queryMyClasses);

  const labs = response
    .items
    .filter(item => item.lab)
    .map(data => new Lab(data));

  // Active labs go first, then timestamp ordering
  labs.sort((a, b) => {
    if (a.isActive() !== b.isActive()) {
      return a.isActive() ? -1 : 1;
    }

    return b.timestamp - a.timestamp;
  });

  return labs;
};

/**
 * Fetches the publish associated with a given class. The publish will contain
 * all of the pertinent metadata as recorded in Airtable.
 *
 * @method
 * @param {Lab} cls The class to query
 * @returns {Activity?} The most recent activity publish associated with the
 *   lab's aggregate, or null if there are no publishes.
 */
export const fetchLatestPublishByContext = async context => {
  window.configure_amplify('arcade_endpoint');

  const response = await asyncSend(listContextTypePublishes, {
    context,
    type: 'activity',
    limit: 100
  });

  const items = response.items;
  items.sort((a, b) => b.version - a.version);

  if (items.length > 0) {
    return Activity.fromPublish(items[0]);
  }

  return null;
};

export const fetchMyLatestPublishByContext = async context => {
  window.configure_amplify('arcade_endpoint');
  
  const response = await asyncSend(listIdentityContextPublishes, {
    context,
    limit: 100,
    filter: JSON.stringify({
      type: { eq: 'activity' }
    })
  });

  const items = response.items;
  items.sort((a, b) => b.version - a.version);

  if (items.length > 0) {
    return Activity.fromPublish(items[0]);
  }

  return null;
};

/**
 * Fetches classes owned by the current user. Only classes that have associated
 * publishes will be returned.
 *
 * @method
 * @async
 * @returns {Promise<Array>} A list of classes
 */
export const fetchMyClasses = async () => {
  window.configure_amplify('arcade_endpoint');

  const response = await asyncSend(queryMyClasses);

  const classData = response
    .items
    .filter(item => !item.lab)
    .map(item => {
      const cls = new Lab(item);
      cls.aggregate = item.aggregate;
      return cls;
    });

  const classes = await Promise.all(classData.map(async cls => {
    const publish = await fetchLatestPublishByContext(`lessons:${cls.aggregate}`);
    cls.publish = publish;
    return cls;
  }));

  classes.sort((a, b) => a.timestamp - b.timestamp)

  return classes.filter(cls => cls.publish);
};

export const lookupUser = async identity => {
  window.configure_amplify('arcade_endpoint');

  const response = await asyncSend(getUser, { identity });

  return response;
};

export const fetchMyPrivateRecord = async () => {
  window.configure_amplify('arcade_endpoint');

  const response = await asyncSend(getUserPrivateRecord);

  return response;
};

export const fetchActivity = async id => {
  try {
    const webview = await getAlgoliaObject(id, 'game');
    return Activity.fromWebview(webview);
  } catch (e) {
    const object = await getAlgoliaObject(id, 'games');
    return Activity.fromGamesSchema(object);
  }
};

export const fetchCourses = async (options = {}) => {
  const { hits } = await searchAlgolia('', 'course', {
    hitsPerPage: 200
  });

  if (!Array.isArray(hits)) {
    return []
  }

  const courses = hits.map(hit => new Course(hit));

  const uniqueCourses = courses
    .filter((course, i, arr) => {
      return arr.findIndex(other => other.context === course.context) === i
    });

  return uniqueCourses;
};

export const fetchSeeds = async (options = {}) => {
  options = {
    context: null,
    ...options
  };
  const facetFilters = (options.subscriptions && options.subscriptions.has('Instructor'))
    ? []
    : ['tags:seed'];

  if (options.context !== null) {
    facetFilters.push(`context_id:lessons:${options.context}`);
  }

  const { hits } = await searchAlgolia('', 'activity', {
    facetFilters,
    hitsPerPage: 200
  });

  if (!Array.isArray(hits)) {
    return [];
  }

  const activities = hits.map(hit => Activity.fromWebview(hit));

  activities.sort((a, b) => b.timestamp - a.timestamp);

  const uniqueActivities = activities
    .filter((activity, i, arr) => {
      return arr.findIndex(other => other.context === activity.context) === i
    });

  return uniqueActivities;
};

export const fetchFeaturedGames = async (options = {}) => {
  return fetchGames({ query: 'Robert Bennett', facetFilters: 'tags:featured', ...options });
};

export const fetchGames = async (options = {}) => {
  const defaults = {
    query: '',
    page: 0
  };

  const { query, ...rest } = { ...defaults, ...options };

  const { hits, nbPages } = await searchAlgolia(query, 'game', rest);

  return {
    hits: Array.isArray(hits) ? hits.map(hit => Activity.fromWebview(hit)) : [],
    isLastPage: nbPages === rest.page
  };
};

export const resolveURI = async (shortenedURI) => {
  const url = getApiEndpoint(`/resolve-uri?code=${shortenedURI}`);
  const response = await fetch(url);
  const { uri } = await response.json();

  return uri;
};

export const updateUserInfo = async data => {
  window.configure_amplify('arcade_endpoint');

  const response = await asyncSend(updatePrivateUser, { input: data });

  return response;
};

export const updatePublicUserInfo = async data => {
  window.configure_amplify('arcade_endpoint');

  const response = await asyncSend(updateUser, { input: data });

  return response;
};

export const updatePublish = async (activity, updates, options = {}) => {
  window.configure_amplify('arcade_endpoint');

  const payload = {
    input: {
      id: activity.id,
      metadata: JSON.stringify({
        ...activity.metadata,
        ...updates
      }),
      ...options
    }
  };

  const response = await asyncSend(updatePublishMetadata, payload);

  return Activity.fromPublish(response);
};
