/**
 * This function will ping a variety of regions on AWS.
 * 
 * INFO: There seems to be some difference between the 'time' in Chrome Network tab and the time here.
 * Chrome Network tab is more accurate.
 * TODO: Can we extract the 'time' in Chrome Network tab?
 * 
 * @param {Function} callback
 * 
 * @returns {Promise<Object[]>}
 */
export const AWSPing = (callback) => {

    // inspired by cloudping.info
    const regions = {
        'us-west-2': 'US-West (Oregon)',
        'us-west-1': 'US-West (California)',
        'eu-central-1': 'Frankfurt',
        'ca-central-1': 'Canada (Central)',
    };

    const specialUrls = {
        'cn-north-1': 'https://ec2.cn-north-1.amazonaws.com.cn/'
    };

    function testUrl(region) {
        return (region in specialUrls ?
            specialUrls[region] : `https://ec2.${region}.amazonaws.com/`) + 'ping';
    }

    function pingRegion(region) {
        const url = testUrl(region);
        const start = Date.now();
        return fetch(url).then(() => Date.now() - start);
    }

    function pingAllRegions() {
        const regionNames = Object.keys(regions);
        const allRegionsPromises = regionNames.map((region) => pingRegion(region));

        return Promise.all(([...allRegionsPromises])).then((allRegionsResponses) => {
            const allRegionsWithPings = regionNames
                .map((region, i) => ({ name: region, title: regions[region], ping: allRegionsResponses[i] }))
                .sort((a, b) => a.ping - b.ping);
            return allRegionsWithPings;
        });
    }

    return pingAllRegions().then(callback).catch(() => []);
}

export const AWSPingAvg = (callback, times = 3) => {
    /**
     * Helper function to get the average of an array of numbers. Will cut off the highest 10% of numbers by default.
     * The reason for cutting off is because the first ping seems to highly skew the results due to DNS lookup, etc.
     * 
     * @param {number[]} arr
     * @param {number} removeMax
     * 
     * @returns {number}
     */
    function getAvgOfArray(arr, removeMax = 0.1) {
        // removeMax === percentage of highest pings to remove as they highly skew the results
        const sortedArr = arr.sort((a, b) => a - b);
        const maxIndex = Math.floor(sortedArr.length * (1 - removeMax));
        const cutArr = sortedArr.slice(0, maxIndex);
        return cutArr.reduce((prevVal, curVal) => prevVal + curVal, 0) / cutArr.length;
    }
    function getFastest(arr, removeMax = 0.1) {
        // removeMax === percentage of highest pings to remove as they highly skew the results
        const sortedArr = arr.sort((a, b) => a - b);
        return sortedArr[0];
    }

    let receivedPings = 0;
    let pings = [];

    const cb = async () => {
        await AWSPing((resPings) => {
            receivedPings++;

            if (receivedPings === times) {
                const pingsResponseArray = pings
                    .map(({ name, title, allPings }) => ({ name, title, ping: getFastest(allPings) }))
                    .sort((a, b) => a.ping < b.ping ? -1 : 1);
                return callback(pingsResponseArray)
            }
            pings = resPings.map((p) => {
                const matchingPing = pings.find((aggregatedPing) => aggregatedPing.name === p.name);
                if (matchingPing) return { ...p, allPings: [...matchingPing.allPings, p.ping] };
                return { ...p, totalPing: p.ping, allPings: [p.ping] };
            });
            return pings;
        });
    }

    for (let i = 0; i < times; i++) {
        setTimeout(cb, i * 150);
    }
}

/**
 * Determines the user's closest region.
 *
 * @see {@link AWSPingAvg}
 * @method
 * @returns {Promise<string>} The name of the closest region to the current user
 */
export const getRegion = () => {
  return new Promise((resolve, reject) => {
    AWSPingAvg(results => resolve(results[0].name));
  });
};
